import axios from 'axios';

const DEVELOP = Boolean(process.env.REACT_APP_DEVELOP) === true;
const LOCAL_SERVER_URL = 'http://localhost:5000';
const url = DEVELOP ? LOCAL_SERVER_URL : '';

const getTrips = async data => {
  const res = await axios.post(url + '/api/trips', { data });
  return res.data;
};

const sendOrder = async data => {
  try {
    const result = await axios.post(url + '/api/order', { data });
    return result;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export default {
  trips: {
    get: getTrips,
  },
  sendOrder,
};
